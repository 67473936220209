<script>
    import appConfig from "@/app.config";
    import helper from "@/assets/helpers/helper";

    /**
     * Contacts-grid component
     */
    export default {
        page: {
            title: "Companies",
            meta: [{ name: "description", content: appConfig.description }]
        },
        name:'Companies',
        data() {
            return {
                companies:[],
                title: "Companies",
                load:false
            };
        },
        created() {
            this.loadCompanies()
        },
        methods: {
            async loadCompanies() {
                this.companies = await helper.getAll('companies');
                this.load=true
            },

        }
    };
</script>

<template>
    <div>
      <div class="row">
        <div class="col-6">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">{{ $t('companies.title') }}</h4>
          </div>
        </div>
        <div class="col-6 py-2 text-right">
          <a class="btn btn-primary" role="button" v-b-modal.modal-collaborate>{{ $t('companies.add_company') }}</a>
        </div>
      </div>
        <div class="row" v-if="load">
            <div v-for="companie in companies" :key="companie.id" class="col-xl-3 col-sm-6 mb-4">
                <div class="card text-center h-100 ">
                    <div class="card-body">
                        <div v-if="companie.logo" class="mb-4">
                            <b-avatar :src="companie.logo" size="lg"  v-if="companie.logo" :alt="companie.name"   button  @click="$router.push({name:'ProfilCompany',params:{id:companie.id}})"></b-avatar>
                            <b-avatar v-else variant="primary" size="lg" :text="(companie.name).substr(0, 1)" class="align-baseline "  button  @click="$router.push({name:'ProfilCompany',params:{id:companie.id}})"></b-avatar>

                        </div>
                        <h5 class="font-size-15 link">
                            <router-link class="text-dark" :to="{name:'ProfilCompany',params:{id:companie.id}}">{{companie.name}}</router-link>
                        </h5>
                        <p >{{companie.short_description}}</p>

                        <div class="group-details  pb-3 d-none d-md-block">

                             <a class="mb-2 text-dark " :href="companie.website_url" target="_blank">{{companie.website_url}}</a>

                        </div>

                    </div>

                </div>
            </div>
            <!-- end col -->
        </div>
        <div class="row" v-else>
            <div v-for="n in 12" class="col-xl-3 col-sm-6 mb-4" :key="n">
                <b-card no-body class="pt-3 text-center">
                    <b-col cols="2" class="m-auto">
                        <b-skeleton type="avatar"></b-skeleton>
                    </b-col>
                    <b-card-body>
                        <b-skeleton width="20%" class="m-auto"></b-skeleton>
                        <b-skeleton  class="my-2"></b-skeleton>
                        <b-skeleton class="mb-2"></b-skeleton>
                        <b-skeleton width="75%" class="mb-2 ml-2"></b-skeleton>
                    </b-card-body>
                </b-card>
            </div>

        </div>
        <!-- end row -->

        <!-- end row -->
    </div>
</template>
<style scoped>
    .iq-media-group .iq-media:hover {
        z-index: 9;
    }
    .iq-media:hover {
        text-decoration: none;
    }
    .iq-media-group .iq-media {
        margin-left: -15px;
        position: relative;
        z-index: 0;
        transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
    }
    .iq-media {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }






</style>